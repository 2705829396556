<template>
  <div class="subscribeTimes">
    <v-list
      ref="list"
      :isAdd="true"
      :isIndex="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot> </template>
      <template #searchSlot>
        <v-input
          placeholder="请输入账号"
          v-model="searchParam.account"
          label="账号"
        ></v-input
        ><v-input
          placeholder="请输入地址名称"
          v-model="searchParam.openUrlName"
          label="地址名称"
        ></v-input
        ><v-select
          clearable
          :options="jusgeStatusList"
          v-model="searchParam.auditStatus"
          @change="$refs.list.search()"
          label="审核状态"
      /></template>
      <template #operateSlot="scope">
        <v-button
          text="审核"
          v-if="scope.row.auditStatus == 1"
          type="text"
          @click="editUrl(scope.row)"
        />
      </template>
    </v-list>
    <v-dialog
      title="审核"
      v-model="isdialog"
      @cancel="cancel"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="确定"
    >
      <div class="item">
        <div class="item-l">账号:</div>
        <div class="item-r">{{ row.account }}</div>
      </div>
      <div class="item">
        <div class="item-l">url地址:</div>
        <div class="item-r">{{ row.openUrl }}</div>
      </div>
      <div class="item">
        <div class="item-l">url地址名称:</div>
        <div class="item-r">{{ row.openUrlName }}</div>
      </div>
      <div class="item">
        <div class="item-l">当前调用次数上限:</div>
        <div class="item-r">{{ row.limitPerDay }}</div>
      </div>
      <div class="item">
        <div class="item-l">申请的次数上限:</div>
        <div class="item-r">{{ row.applyLimitPerDay }}</div>
      </div>
      <div class="item">
        <div class="item-l">申请理由:</div>
        <div class="item-r">{{ row.applyReason }}</div>
      </div>
      <div class="item">
        <div class="item-l">审核结果:</div>
        <div class="item-r">
          <v-select
            :options="jusgeStatusRea"
            v-model="form.auditStatus"
            :width="250"
          />
        </div>
      </div>
      <div class="item te">
        <div class="item-l">审核备注:</div>
        <div class="item-r">
          <v-input
            type="textarea"
            placeholder="请输入审核备注"
            v-model="form.auditRemarks"
            :width="250"
          ></v-input>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { authApplyUrl, judgeApplyUrl } from "./api.js";
import { jusgeStatusListMap, jusgeStatusList, jusgeStatusRea } from "./map";

export default {
  name: "subscribeTimes",
  data() {
    return {
      isdialog: false,
      jusgeStatusList,
      id: "",
      jusgeStatusRea,
      searchParam: {
        openUrlName: "",
        auditStatus: "",
        account: "",
      },
      row: {},
      form: {
        id: "",
        limitPerDay: "",
        auditStatus: 2,
        auditRemarks: "",
      },
      tableUrl: authApplyUrl,
      headers: [
        {
          prop: "account",
          label: "账号",
        },
        {
          prop: "openUrl",
          label: "url地址",
        },
        {
          prop: "openUrlName",
          label: "url地址名称",
        },
        {
          prop: "limitPerDay",
          label: "当前调用次数上限",
        },
        {
          prop: "applyLimitPerDay",
          label: "申请的次数上限",
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          formatter: (row, prop) => jusgeStatusListMap[row.auditStatus],
        },
        {
          prop: "createTime",
          label: "审核时间",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    cancel() {
      this.isdialog = false;
    },
    async confirm() {
      let params = {
        id: this.row.id,
        limitPerDay: this.row.applyLimitPerDay,
        auditStatus: this.form.auditStatus,
        auditRemarks: this.form.auditRemarks,
      };
      let res = await this.$axios.post(
        `${judgeApplyUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.$message.success("操作成功");
        this.isdialog = false;
        this.$refs.list.search();
      }
    },
    editUrl(row) {
      this.row = row;
      this.form = {
        id: "",
        limitPerDay: "",
        auditStatus: 2,
        auditRemarks: "",
      };
      this.isdialog = true;
    },
    toAdd() {},
    deleteUrl(id) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: id,
        };
        this.$axios
          .post(`${judgeApplyUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.subscribeTimes {
  box-sizing: border-box;
  height: 100%;
  .item {
    margin: 20px 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    .item-l {
      width: 134px;
      text-align: right;
      margin-right: 14px;
    }
    .item-r {
      flex: 1;
      /deep/ textarea {
        height: 100px;
      }
    }
    /deep/ .v-input {
      display: flex;
      align-items: center;
    }
  }
  .te {
    align-items: flex-start;
  }
}
</style>
